module controllers {
    export module master {

        interface ICustomsRateOfExchangeCtrlScope extends ng.IScope {
        }

        export class customsRateOfExchangeCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'countryService',
                'customsRateOfExchangeService',
            ];

            selectedCountry: interfaces.applicationcore.IDropdownModel;
            selectedDate: moment.Moment;
            selectedCountryId: number;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.ICustomsRateOfExchange>;
            refresh: boolean = true;

            IsLoading: boolean = false;
            currentPage: number = 1;
            pageSize: number = 10;

            searchAccordian: boolean = false;
            showAllActive: boolean = true;

            product: interfaces.applicationcore.IDropdownModel;

            constructor(private $scope: ICustomsRateOfExchangeCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private countryService: interfaces.applicationcore.ICountryService,
                private customsRateOfExchangeService: interfaces.master.ICustomsRateOfExchangeService,
            ) {
                this.loadRates(true);
                this.loadDefaultCountry();
            }

            loadCountries(searchText: string) {
                return this.countryService.getDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }
            
            countryChange(model: interfaces.applicationcore.IDropdownModel) {
                this.selectedCountry = model;
            }

            loadDefaultCountry()
            {
                this.countryService.GetCurrentCountry().get({  }, (result: interfaces.applicationcore.ICountry) => {
                    this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        Selected: true
                    };
                });
            }

            loadRates(onLoad: boolean) {
                if (this.selectedCountry) {
                    this.selectedCountryId = this.selectedCountry.Id;
                } else {
                    this.selectedCountryId = 0;
                }

                if (!this.selectedDate)
                {
                    this.selectedDate = moment.utc();
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'customs.exchange.rate'
                },
                    () => {
                        return this.customsRateOfExchangeService.getList().query({
                            selectedDate: this.selectedDate.format("YYYYMMDDTHH:mm"),
                            countryId: this.selectedCountryId,
                            numberRecords: this.pageSize,
                            pageNumber: this.currentPage,
                        }, (result: Array<interfaces.master.ICustomsRateOfExchange>) => {
                            this.gvwExchangeRates.data = result;

                            if (result[0]) {
                                this.gvwExchangeRates.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwExchangeRates.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            selectedDateChanged(newValue: moment.Moment)
            {
                this.selectedDate = newValue;
            }

            public gvwExchangeRates: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "CURRENCY",
                    displayName: "Transaction Currency",
                    field: "Currency",
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                }, {
                    name: "COUNTRY",
                    displayName: "To Country",
                    field: "Country",
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                }, {
                    name: "EXCHANGERATE",
                    displayName: "Exchange Rate",
                    field: "ExchangeRate",
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | number:6}}</div>`,
                }, {
                    name: "NUMRECS",
                    displayName: "",
                    field: "NumRecs",
                    visible: false
                }]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
            }

            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {

                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.master.ICustomsRateOfExchange = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.currentPage = newPage;
                this.pageSize = pageSize;

                this.DoSearch();
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'customs.exchange.rate'
                },
                    () => {
                        return this.customsRateOfExchangeService.getList().query({
                            selectedDate: this.selectedDate.format("YYYYMMDDTHH:mm"),
                            countryId: this.selectedCountryId,
                            numberRecords: this.pageSize,
                            pageNumber: this.currentPage,
                        }, (result: Array<interfaces.master.ICustomsRateOfExchange>) => {
                            var currentDirtyRows: Array<interfaces.master.ICustomsRateOfExchange> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.master.ICustomsRateOfExchange = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });

                            this.gvwExchangeRates.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwExchangeRates.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwExchangeRates.totalItems = 0;
                            }
                        }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });
            }
        }

        angular.module("app").controller("customsRateOfExchangeCtrl", controllers.master.customsRateOfExchangeCtrl);
    }
}